export enum HomeMenuPage {
  NONE = "",
  FEATURES = "features",
  PRODS = "products",
  PRODS_O = "products_one",
  PRODS_TW = "products_two",
  PRODS_T = "products_three",
  USEFULLS = "usefulls",
  EXPIRIENCE = "expirience"
}

export enum ControllerName {
  USERS = "/users",
  PROJECTS = "/projects",
  CORE = "/core",
  CRM = "/crm",
  STORE = "/store",
  STUDIOS = "/studios",
  BLOG = "/articles",
  BRANDS = "/brands"
}

// export const API_URL = "http://localhost:4249";
export const API_URL = "https://api.podcastbery.ru";
// export const API_URL = "https://download.topgunadsnetwork.ru";

export const SNG_OR_WRLD: "sng" | "world" = "sng"

export type SliderToggleDirection = "next" | "prev";
export enum SliderToggleDir {
  NEXT = "next",
  PREV = "prev"
}

export enum StorageKeys {
  AUTHED = "nx_fermstory_authed",
  USER = "nx_fermstory_user",
  TOKEN = "nx_fermstory_token",
  GOOGLE_TOKEN = "nx_fermstory_google_token",

  ADMIN_CRM_VIEW_TYPE = "nx_fermstory_crm_view_type",

  CITY_QUEST_SHOWN = "nx_fermstory_city_quest",

  GLOBAL_SEARCH_TEXT = "nx_fermstory_gsearch_text",

  LOCK_SCREEN = "nx_fermstory_lock_screen",

  SETTING_ADMIN_SIDEBAR = "nx_fermstory_sidebar",

  SETTINGS_HOME_IMGS = "nx_fermstory_home_images",
  SETTINGS_CONTACTS = "nx_fermstory_contacts",
  SETTINGS_HSS = "nx_fermstory_hss",
  SETTINGS_TRANS = "nx_fermstory_trans",
  SETTINGS_TRANS_ST = "nx_fermstory_trans_st",
  SETTINGS_FOOTER_DATA = "nx_fermstory_footer_data",
  SETTINGS_ABOUT = "nx_fermstory_about_data",
  SETTINGS_SEO = "nx_fermstory_seo_config",
  SETTINGS_SERVICES = "nx_fermstory_services_config",

  PROJECTS = "nx_fermstory_projects",

  LOCALE = "nx_pb_locale",
  COUNTRY = "nx_pb_country"
}

export const TRANS_LANGS = [
  {
    "name": "Русский",
    "nameEn": "Russian",
    "items": [
      { "name": "head_home", "value": "Главная", "value_en": "Home" },
      { "name": "head_studios", "value": "Творческие студии", "value_en": "Home" },
      { "name": "head_partners", "value": "Стать партнером", "value_en": "Home" },
      { "name": "head_about", "value": "О сервисе", "value_en": "Home" },
      { "name": "head_cabinet", "value": "Личный кабинет", "value_en": "Home" },
      { "name": "head_login", "value": "Войти", "value_en": "Home" },
      { "name": "head_signup", "value": "Регистрация", "value_en": "Home" },

      { "name": "select_country", "value": "Выберите страну", "value_en": "Home" },
      { "name": "select_option", "value": "Выбрать", "value_en": "Home" },
      
      { "name": "footer_clients", "value": "ДЛЯ КЛИЕНТОВ", "value_en": "Home" },
      { "name": "footer_studios", "value": "ДЛЯ СТУДИЙ", "value_en": "Home" },
      { "name": "footer_c_blog", "value": "Блог", "value_en": "Home" },
      { "name": "footer_c_podcasts", "value": "Новые подкасты", "value_en": "Home" },
      { "name": "footer_c_agree", "value": "Пользовательское соглашение", "value_en": "Home" },
      { "name": "footer_s_partner", "value": "Стать партнером", "value_en": "Home" },
      { "name": "footer_s_cabinet", "value": "Личный кабинет партнера", "value_en": "Home" },
      { "name": "footer_s_agree", "value": "Партнерское соглашение", "value_en": "Home" },
      { "name": "footer_copys", "value": "© 2024 «ПОДКАСТБЕРИ»", "value_en": "Home" },
      { "name": "footer_cities", "value": "Города присутствия", "value_en": "Home" },
      { "name": "footer_policy", "value": "Политика конфиденциальности", "value_en": "Home" },

      { "name": "home_title", "value": "Откройте мир подкастов", "value_en": "Home" },
      { "name": "home_subtitle", "value": "Найдите студию для записи подкастов, звукозаписи, видеопродакшена", "value_en": "Home" },
      { "name": "home_text", "value": "Уникальный агрегатор подкаст студий, где каждая запись становится произведением искусства. Мы предлагаем вам удобную платформу, где вы можете найти, сравнить и забронировать студию, идеально подходящую для ваших творческих идей.", "value_en": "Home" },
      { "name": "home_action", "value": "Найти студию", "value_en": "Home" },
      
      { "name": "home_why", "value": "Почему выбирают нас?", "value_en": "Home" },
      { "name": "home_why_b1", "value": "Выбирайте студию под свои цели", "value_en": "Home" },
      { "name": "home_why_b1_txt", "value": "Каждая студия представлена с подробным описанием, отзывами и уникальными предложениями, что позволяет вам принимать обоснованное решение.", "value_en": "Home" },
      { "name": "home_why_b2", "value": "Автоматизированный процесс бронирования и отмены", "value_en": "Home" },
      { "name": "home_why_b2_txt", "value": "Добавляйте студию в избранное и удобным способом повторно бронируйте площадку через историю заказов.", "value_en": "Home" },
      { "name": "home_why_b3", "value": "Удобный поиск", "value_en": "Home" },
      { "name": "home_why_b3_txt", "value": "Интуитивный интерфейс, где легко можно найти и забронировать нужную студию.", "value_en": "Home" },
      { "name": "home_why_b4", "value": "Разнообразие студий", "value_en": "Home" },
      { "name": "home_why_b4_txt", "value": "Мы предлагаем разнообразные варианты: от уютных домашних студий до профессионально оборудованных пространств с современными технологиями.", "value_en": "Home" },
      { "name": "home_why_b5", "value": "Будь в курсе", "value_en": "Home" },
      { "name": "home_why_b5_txt", "value": "Рассказываем про новые подкаст-проекты в индустрии.", "value_en": "Home" },
      
      { "name": "home_why_txt", "value": "Не позволяйте идеям оставаться несозданными: забронируйте студию без лишних хлопот и погрузитесь в мир звукового творчества. Начните ваше путешествие в мир подкастов уже сегодня и дайте волю своим мыслям, рассказам и идеям!", "value_en": "Home" },
      
      { "name": "home_cats", "value": "Выберите направление", "value_en": "Home" },
      { "name": "home_cats_all", "value": "Все направления", "value_en": "Home" },
      
      { "name": "home_partners", "value": "Наши партнеры", "value_en": "Home" },
      
      { "name": "studios_filters", "value": "Фильтры", "value_en": "Home" },
      { "name": "studios_sort", "value": "Сортировка", "value_en": "Home" },
      { "name": "studios_srch", "value": "Подкастбери", "value_en": "Home" },
      { "name": "studios_list", "value": "Списком", "value_en": "Home" },
      { "name": "studios_onmap", "value": "На карте", "value_en": "Home" },
      
      { "name": "studios_srch_city", "value": "Город", "value_en": "Home" },
      { "name": "studios_srch_metro", "value": "Метро", "value_en": "Home" },
      { "name": "studios_srch_cats", "value": "Направление", "value_en": "Home" },
      { "name": "studios_srch_date", "value": "Дата", "value_en": "Home" },
      { "name": "studios_srch_all", "value": "Все", "value_en": "Home" },
      { "name": "studios_srch_action", "value": "Найти", "value_en": "Home" },
      { "name": "studios_srch_selcat", "value": "Выберите направление", "value_en": "Home" },
      { "name": "studios_srch_selcity", "value": "Выберите город", "value_en": "Home" },
      { "name": "studios_srch_selmetro", "value": "Выберите метро", "value_en": "Home" },
      { "name": "studios_srch_seldate", "value": "Выберите дату", "value_en": "Home" },
      { "name": "studios_srch_deldate", "value": "Очистить", "value_en": "Home" },
      
      { "name": "studios_search", "value": "Поиск", "value_en": "Home" },
      { "name": "studios_srch_not", "value": "Не указывать", "value_en": "Home" },
      
      { "name": "studios_notfound", "value": "К сожалению, мы не нашли студии по заданным параметрам...", "value_en": "Home" },
      { "name": "studios_others", "value": "Другие студии в этом городе", "value_en": "Home" },
      
      { "name": "studios_comeback", "value": "Сколько человек вернулось в студию", "value_en": "Home" },
      
      { "name": "sort", "value": "Сортировка", "value_en": "Home" },
      { "name": "sort_def", "value": "По умолчанию", "value_en": "Home" },
      { "name": "sort_min", "value": "Дешевле", "value_en": "Home" },
      { "name": "sort_max", "value": "Дороже", "value_en": "Home" },
      { "name": "sort_rate", "value": "По рейтингу", "value_en": "Home" },
      { "name": "sort_accept", "value": "Применить", "value_en": "Home" },
      
      { "name": "filters", "value": "Фильтры", "value_en": "Home" },
      { "name": "filters_accept", "value": "Применить", "value_en": "Home" },
      { "name": "filters_facils", "value": "Удобства", "value_en": "Home" },
      { "name": "filters_servs", "value": "Сервис", "value_en": "Home" },
      
      { "name": "studio_time", "value": "ВРЕМЯ РАБОТЫ", "value_en": "Home" },
      { "name": "studio_time_o", "value": "будни", "value_en": "Home" },
      { "name": "studio_time_s", "value": "выходные", "value_en": "Home" },
      { "name": "studio_desc", "value": "Описание", "value_en": "Description" },
      { "name": "studio_servs", "value": "Услуги", "value_en": "Services" },
      { "name": "studio_revs", "value": "Отзывы", "value_en": "Reviews" },
      { "name": "studio_book", "value": "Забронировать", "value_en": "Book now" },
      { "name": "studio_not_info", "value": "Студия пока что не разместила о себе информацию", "value_en": "Home" },
      { "name": "studio_tools", "value": "Оборудование", "value_en": "Home" },
      { "name": "studio_not_servs", "value": "Услуги отсутствуют", "value_en": "Home" },
      { "name": "studio_not_srv", "value": "Студия пока что не разместила свои услуги", "value_en": "Home" },
      { "name": "studio_mins", "value": "минут", "value_en": "Home" },
      { "name": "studio_book_online", "value": "Онлайн бронирование", "value_en": "Home" },
      { "name": "studio_not_tt", "value": "Студия пока что не разместила актуальное расписание", "value_en": "Home" },
      { "name": "studio_contacts", "value": "Контакты", "value_en": "Contacts" },
      { "name": "studio_addr", "value": "Адрес", "value_en": "Address" },
      { "name": "studio_phone", "value": "Телефон", "value_en": "Phone number" },
      { "name": "studio_web", "value": "Веб-сайт", "value_en": "Website" },
      { "name": "studio_empty", "value": "Тут пока что пусто...", "value_en": "Home" },
      { "name": "studio_info", "value": "Информация", "value_en": "Home" },
      { "name": "studio_pls", "value": "Пожалуйста, скажите студии, что вы нашли их через сервис podcastbery.ru. Это очень важно для нас, благодарим.", "value_en": "Home" },
      { "name": "studio_open", "value": "Данные об этой компании были взяты с открытых источников", "value_en": "Home" },
      { "name": "studio_rev", "value": "Отзыв", "value_en": "Review" },
      { "name": "studio_more", "value": "Подробнее", "value_en": "More" },
      
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
      { "name": "home_", "value": "Выберитенаправление", "value_en": "Home" },
    ]
  },
  {
    "name": "Анлглийский",
    "nameEn": "English",
    "items": [
      { "name": "head_home", "value": "Главная", "value_en": "Home" }
    ]
  }
]

export const ruPickerLang = {
  today: "Сегодня",
  now: "Сегодня",
  ok: "Выбрать",
  backToToday: "Вернуться к сегодня",
  timeSelect: "Выбрать время",
  dateSelect: "Выбрать дату",
  clear: "Сбросить",
  month: "Месяц",
  year: "Год",
  previousMonth: "Предыдущий месяц",
  nextMonth: "Следующий месяц",
  monthSelect: "Выбрать месяц",
  yearSelect: "Выбрать год",
  decadeSelect: "Выбрать декаду",
  previousYear: "Предыдущий год",
  nextYear: "Следующий год",
  previousDecade: "Предыдущая декада",
  nextDecade: "Следующая декада",
  previousCentury: "Предыдущий век",
  nextCentury: "Следующий век",

  yearFormat: "YYYY",
  dateFormat: "M/D/YYYY",
  dayFormat: "D",
  dateTimeFormat: "M/D/YYYY HH:mm:ss",
  monthFormat: "MMMM",

  shortMonths: ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"],
  shortWeekDays: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"]
}