import { makeAutoObservable } from "mobx";
import moment from "moment";
import {StorageKeys} from "../utils/defaults";

export default class StudioStore {

  cities: any[] = []
  countries: any[] = []
  selCountry: any = null
  preSelCountry: any = null
  selCountryName: string = ""
  selectedCity: any | null = null
  selectedCityName: string = ""
  
  isCtrVisible: boolean = false
  isCityVisible: boolean = false
  cityQuestShown: boolean = false
  cityQuestVisible: boolean = false

  pageIndex: number = 1
  pageLimit: number = 30
  search: string = ""
  metro: string = ""
  category: string = "all"
  categorySN: string = ""
  order: string = "DESC"
  orderBy: string = "id"
  status: string = "all"

  isMainLoading: boolean = false

  searchDate: string = ""
  searchTime: string = ""
  searchService: string = ""
  serviceSearch: string = ""

  studiosPreview: any[] = []

  studios: any[] = []
  studiosOthers: any[] = []
  totalStudios: number = 0

  selectedStudio: any | null = null

  orders: any[] = []

  podcasts: any[] = []
  articles: any[] = []

  fixedLine: boolean = false

  cityWindow: boolean = false
  catWindow: boolean = false
  metroWindow: boolean = false
  servWindow: boolean = false
  dateWindow: boolean = false
  timeWindow: boolean = false

  studioTabsFixed: boolean = false

  bookingFormVisible: boolean = false
  bookingFormVisibleP: boolean = false

  servicesTxt: string = ""
  bookingServices: any[] = []

  allCats: any[] = []
  selCat: number = 0

  maxDate: moment.Moment | null = null

  selectedTime: string = ""
  selectedDate: moment.Moment = moment()

  studioImagesStr: string = ""
  studioImages: any[] = []

  studioEImagesStr: string = ""
  studioEImages: any[] = []

  selectedCat: any | null = null

  likes: any[] = []

  selectedSort: string = "default"
  selectedFacils: any[] = []
  selectedServs: any[] = []

  resetFilters: boolean = false

  selStudio: any | null = null

  metros: any[] = []

  constructor() {
    makeAutoObservable(this);
  }

  setMetros(val: any[]) {
    this.metros = val
  }

  setSelStudio(val: any) {
    this.selStudio = val
  }

  setResetFilters(value: boolean) {
    this.resetFilters = value
  }

  setSelectedSort(val: string) {
    this.selectedSort = val
  }

  setSelectedServs(val: any[]) {
    this.selectedServs = val
  }

  setSelectedFacils(val: any[]) {
    this.selectedFacils = val
  }

  setIsMainLoading(val: boolean) {
    this.isMainLoading = val
  }

  setLikes(lst: any[]) {
    this.likes = lst
  }

  setSelectedCat(val: any) {
    this.selectedCat = val
  }

  setStudioImages(val: any[]) {
    this.studioImages = val
  }

  setStudioImagesStr(val: string) {
    this.studioImagesStr = val
  }

  setEStudioImages(val: any[]) {
    this.studioEImages = val
  }

  setEStudioImagesStr(val: string) {
    this.studioEImagesStr = val
  }

  setSelectedDate(val: any) {
    this.selectedDate = val
  }

  setSelectedTime(time: string) {
    this.selectedTime = time
  }

  setMaxDate(date: moment.Moment | null) {
    this.maxDate = date
  }

  setSelCat(cat: number) {
    this.selCat = cat
  }

  setAllCats(cats: any[]) {
    this.allCats = cats
  }

  setBookingServices(val: any[]) {
    this.bookingServices = val
  }

  setServicesTxt(s: string) {
    this.servicesTxt = s
  }

  setBookingFormVisible(visible: boolean) {
    this.bookingFormVisible = visible
  }

  setBookingFormVisibleP(visible: boolean) {
    this.bookingFormVisibleP = visible
  }

  setStudioTabsFixed(value: boolean) {
    this.studioTabsFixed = value
  }

  setSearchDate(val: string) {
    this.searchDate = val
  }

  setSearchTime(val: string) {
    this.searchTime = val
  }

  setSearchService(val: string) {
    this.searchService = val
  }

  setServiceSearch(val: string) {
    this.serviceSearch = val
  }

  setTimeWindow(val: boolean) {
    this.timeWindow = val
  }

  setDateWindow(val: boolean) {
    this.dateWindow = val
  }

  setServWindow(val: boolean) {
    this.servWindow = val
  }

  setCatWindow(val: boolean) {
    this.catWindow = val
  }

  setMetroWindow(val: boolean) {
    this.metroWindow = val
  }

  setCityWindow(val: boolean) {
    this.cityWindow = val
  }

  setFixedLine(value: boolean) {
    this.fixedLine = value
  }

  setPodcasts(val: any) {
    this.podcasts = val
  }

  setArticles(val: any) {
    this.articles = val
  }

  setOrders(val: any[]) {
    this.orders = val
  }

  setStudios(val: any[]) {
    this.studios = val
  }

  setStudiosOthers(val: any[]) {
    this.studiosOthers = val
  }

  setSelectedStudio(val: any) {
    this.selectedStudio = val
  }

  setTotalStudios(val: number) {
    this.totalStudios = val
  }

  setSearch(val: string) {
    this.search = val
  }

  setCategorySN(val: string) {
    this.categorySN = val
  }

  setCategory(val: string) {
    this.category = val
  }

  setMetro(val: string) {
    this.metro = val
  }

  setOrder(val: string) {
    this.order = val
  }

  setOrderBy(val: string) {
    this.orderBy = val
  }

  setStatus(val: string) {
    this.status = val
  }

  setPageLimit(val: number) {
    this.pageLimit = val
  }

  setPageIndex(val: number) {
    this.pageIndex = val
  }

  setCities(val: any[]) {
    this.cities = val
  }

  setPreSelCountry(val: any) {
    this.preSelCountry = val
  }

  setSelCountry(val: any) {
    this.selCountry = val
  }

  setSelCountryName(val: string) {
    this.selCountryName = val
  }

  setCountries(val: any[]) {
    this.countries = val
  }

  setSelectedCityName(val: string) {
    this.selectedCityName = val
  }

  setSelectedCity(val: any) {
    this.selectedCity = val
  }

  setIsCtrVisible(val: boolean) {
    this.isCtrVisible = val
  }

  setIsCityVisible(val: boolean) {
    this.isCityVisible = val
  }

  openCityQuest() {
    const already = localStorage.getItem(StorageKeys.CITY_QUEST_SHOWN)
    if (already != null) {
      return
    }
    this.cityQuestShown = true
    setTimeout(() => {
      this.cityQuestVisible = true
    }, 100)
  }

  closeCityQuest() {
    localStorage.setItem(StorageKeys.CITY_QUEST_SHOWN, "true")
    this.cityQuestVisible = false
    setTimeout(() => {
      this.cityQuestShown = false
    }, 300)
  }

  setCityQuestVisible(val: boolean) {
    this.cityQuestVisible = val
  }

  setCityQuestShown(val: boolean) {
    this.cityQuestShown = val
  }

}