import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import ImageSlider from "../components/ImageSlider"
import { Context } from ".."

import styles from "../styles/Cities.module.scss"
import useWindowDimensions from "../core/hooks/useWindowDimensions"

const Cities: FC = () => {

  const { store, studioStore, adminStore } = useContext(Context)
  const { width } = useWindowDimensions()

  useEffect(() => {
    return () => {
      
    }
  }, [])

  return (
    <>
      <div className={styles.cities}>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className={styles.wrapper}>
                <h1>{store.locale == "ru-RU" ? "Города присутствия" : "Cities of presence"}</h1>
                
                <div className={styles.cities_list}>
                  {studioStore.cities.map((city: any) => <div><a href={`/studios/${city.code}`}>{store.locale == "ru-RU" ? city.name : city.name_en}</a></div>)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(Cities)