import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { Context } from "../../.."

import styles from "./Breadcrumbs.module.scss"

export interface BreadcrumbsProps {

}

export interface BreadcrumbsPath {
  path: string
  title: string
  title_en?: string
}

const BreadPaths: BreadcrumbsPath[] = [
  {path: "", title: "Главная", title_en: "Home"},
  {path: "studios", title: "Творческие студии", title_en: "Creative studios"},
  {path: "basket", title: "Корзина", title_en: "Basket"},
  {path: "orders", title: "Заказы", title_en: "Orders"},
  {path: "studio", title: "Данные студии", title_en: "Studio details"},
  {path: "analitycs", title: "Аналитика", title_en: "Analytics"},
  {path: "services", title: "Услуги", title_en: "Services"},
  {path: "timings", title: "Расписание", title_en: "Schedule"},
  {path: "promos", title: "Промокоды", title_en: "Promocodes"},
  {path: "account", title: "Аккаунт", title_en: "Account"},
  {path: "signin", title: "Авторизация", title_en: "Authorization"},
  {path: "favorites", title: "Избранное", title_en: "Favorites"},
  {path: "blog", title: "Блог", title_en: "Blog"},
  {path: "podcasts", title: "Подкасты", title_en: "Podcasts"},
  {path: "rules", title: "Правила", title_en: "Rules"},
  {path: "partner", title: "Личный кабинет партнера", title_en: "Partner cabinet"},
  {path: "connect", title: "Подать заявку", title_en: "Submit an application"},
  {path: "about", title: "О нас", title_en: "About us"},
  {path: "delivery", title: "Доставка", title_en: "Delivery"}
]

const Breadcrumbs: FC<BreadcrumbsProps> = ({}) => {
  
  const { store, pagesStore, studioStore } = useContext(Context)
  
  const location = useLocation()

  const [breadcrumbs, setBreadcrumbs] = useState([] as any[])
  const [existsCity, setExistsCity] = useState(null as any | null)

  const parseBreads = () => {
    let newBreads: any[] = []

    let paths: string[] = location.pathname.split("/")

    let exCityA: any = null

    if (paths.length > 0 && paths[1] != "") {
      paths.forEach((pth, ix) => {
        let isBlogPath = paths.includes("blog") && pth != "blog"
        let isStudioPath = paths.includes("studios") && pth != "studios"

        let exCity = studioStore.cities.find(v => v.code == pth)
        let isCityPath = exCity != undefined
        console.log(ix, pth, exCity, isCityPath, isStudioPath)

        if (exCity != undefined) {
          exCityA = exCity
        }

        if (ix != 0) {
          if (pth != "" && pth != "/") {
            newBreads.push({ is_city: isCityPath, url: isCityPath ? `/studios/${pth}` : `/${pth}`, name: isBlogPath ? (pagesStore.selectedPost != null ? pagesStore.selectedPost.title : "") : (isCityPath ? (store.locale == "ru-RU" ? exCity.name : exCity.name_en) : (isStudioPath ? (pagesStore.selectedBrand != null ? pagesStore.selectedBrand.name : "") : getBreadName(pth))) })
          }
        } else {
          newBreads.push({ url: `/${pth}`, name: getBreadName(pth) })
        }
      })
    } else {
      newBreads.push({url: `/`, name: "Главная"})
    }

    setExistsCity((prev: any) => prev = exCityA)

    console.log(newBreads)

    setBreadcrumbs((prev: any) => prev = newBreads)
  }

  const getBreadName = (path: string) => {
    const exists = BreadPaths.find(v => v.path == path)
    if (exists != undefined) {
      return store.locale == "ru-RU" ? exists.title : exists.title_en
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (studioStore.cities.length > 0) {
      setTimeout(() => {
        parseBreads()
      }, 1000)
    }
  }, [location, studioStore.cities, store.locale])

  return (
    <>
      <div className={styles.breadcrumbs} itemScope itemType="https://schema.org/BreadcrumbList">
        {breadcrumbs.map((bread, ix) => <div itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
          <a itemProp="item" href={ix == breadcrumbs.length - 1 ? `${location.pathname}` : `${existsCity != null && `/${existsCity.code}` == bread.url ? `studios/${bread.url}` : bread.url}`}><span itemProp="name">{bread.name}</span></a>
          <meta itemProp="position" content={`${ix + 1}`} />
        </div>)}
      </div>
    </>
  )
}

export default observer(Breadcrumbs)