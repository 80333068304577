import { observer } from "mobx-react-lite"
import { FC } from "react"
import {useLocation} from "react-router-dom"
import useWindowDimensions from "../../../core/hooks/useWindowDimensions"

import styles from "./CardWrap.module.scss"

export interface CardWrapProps {
  minHeight?: string
  children: any
}

const CardWrap: FC<CardWrapProps> = ({
  children, minHeight
}) => {

  const { width } = useWindowDimensions()
  const location = useLocation()

  return (
    <>
      <div className={location.pathname.includes("/partner") ? "container2" : (width > 960 ? "container" : "container")} style={location.pathname.includes("/partner") ? {
        maxWidth: "95%",
        margin: "0 auto"
      } : {}}>
        <div className={width > 960 ? "row" : "row"}>
          <div className={width > 960 ? "col-12" : "col-12"}>
            <div className={styles.card_wrap} id="cardwrap" style={{minHeight: minHeight}}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(CardWrap)