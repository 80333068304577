import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useState } from "react"
import { Context } from ".."

import styles from "../styles/Home.module.scss"

const NotFoundPage: FC = () => {

  const { store } = useContext(Context)

  const [anim1, setAnim1] = useState(false)

  useEffect(() => {
    // window.location.href = "/studios"
  }, [])

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className={styles.not_found}>
              <strong>404</strong>
              <h1>{store.locale == "ru-RU" ? "Страница не найдена" : "Page not found"}</h1>
              <a href="/">{store.locale == "ru-RU" ? "На главную" : "To home"}</a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default observer(NotFoundPage)