import moment from "moment";
import 'moment/locale/ru'
import { useMemo } from "react"

export const getDateFormat = (date: string, format: string = "DD MMM YYYY, HH:mm") => {
  moment.lang("ru");
  moment.locale("ru");
  return moment(date).format(format);
}

export const getDateTSFormat = (ts: number, format: string = "DD MMM YYYY, HH:mm") => {
  return moment(ts).format(format);
}

export function shuffle(array: any[]) {
  let currentIndex = array.length
  // While there remain elements to shuffle...
  while (currentIndex != 0) {
    // Pick a remaining element...
    let randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--
    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]]
  }

  return array
}

export function declension(str: string) {
  //«а», «у», «о», «и», «э», «ы», «я», «ю», «е», «ё»
  const alph = ["а", "е", "ё", "и", "о", "у", "э", "ю", "ы", "я", "ь"]
  let newstr = str
  let lstChar = str[str.length - 1]

  if (alph.includes(lstChar)) {
    newstr = `${str.slice(0, str.length - 1)}е`
  } else {
    newstr = `${str}е`
  }

  if (str.toLowerCase().includes("грозный")) {
    return "Грозном"
  }

  if (str.toLowerCase().includes("тюмень")) {
    return "Тюмени"
  }

  if (str.toLowerCase().includes("рязань")) {
    return "Рязани"
  }

  if (str.toLowerCase().includes("сочи")) {
    return "Сочи"
  }

  if (str.toLowerCase().includes("чебоксар")) {
    return "Чебоксарах"
  }

  if (str.toLowerCase().includes("пермь")) {
    return "Перми"
  }

  if (str.toLowerCase().includes("тверь")) {
    return "Твери"
  }

  if (str.toLowerCase().includes("тольят")) {
    return "Тольятти"
  }

  if (str.toLowerCase().includes("иванов")) {
    return "Иваново"
  }

  if (str.toLowerCase().includes("волжск")) {
    return "Волжском"
  }

  if (str.toLowerCase().includes("астрахан")) {
    return "Астрахани"
  }

  if (str.toLowerCase().includes("казан")) {
    return "Казани"
  }

  if (str.toLowerCase().includes("тагил")) {
    return "Нижнем тагиле"
  }

  if (str.toLowerCase().includes("нижний")) {
    return "Нижнем новгороде"
  }

  if (str.toLowerCase().includes("ростов")) {
    return "Ростове-на-дону"
  }

  if (str.toLowerCase().includes("набережные")) {
    return "Набережных челнах"
  }

  return newstr
}

export const GLOBAL_CATS: any[] = [
  {name: "zapis-podkastov", orig: "Запись подкастов", val: "Студии записи подкастов"},
  {name: "zvukozapis", orig: "Звукозапись", val: "Студии звукозаписи"},
  {name: "videoprodakshn", orig: "Видеопродакшен", val: "Студии видеопродакшена"},
  {name: "zapis-vebinara-kursa", orig: "Запись вебинара/курса", val: "Студии записи вебинара/курса"},
  {name: "zapis-korotkih-video-dlya-soc-setey", orig: "Запись коротких видео для социальных сетей", val: "Студии записи коротких видео для социальных сетей"},
  {name: "arenda-studii", orig: "Аренда студии", val: "Аренда студий"},
  {name: "vyezdnaya-zapis-podkastov", orig: "Выездная запись подкастов", val: "Выездная запись подкастов"},
  {name: "arenda-oborudovaniya", orig: "Аренда оборудования", val: "Аренда оборудования"}
]

export const getCountryCode = (country: string) => {
  if (country.slice(0, 2).toLowerCase() == "ru") {
    return "ru-RU"
  } else if (country.slice(0, 2).toLowerCase() == "en") {
    return "en-EN"
  } else if (country.slice(0, 2).toLowerCase() == "fr") {
    return "fr-FR"
  }
  return "ru-RU"
}

export function declensionR(str: string) {
  const ex = GLOBAL_CATS.find(v => v.orig == str)
  if (ex == undefined) {
    return "Студии записи подкастов"
  } else {
    return ex.val
  }
}

export const sortByTime = (a: any, b: any) => {
  if (parseInt(a.time.replace(":")) > parseInt(b.time.replace(":"))) {
    return 1
  } else if (parseInt(a.time.replace(":")) < parseInt(b.time.replace(":"))) {
    return -1
  } else {
    return 0
  }
}

export const prevent = (ev: any) => {
  ev.preventDefault()
  ev.stopPropagation()
}

export function doScrolling(element: string, duration: number) {
  let startingY = window.pageYOffset
  let elementY = getElementY(element)
  // If element is close to page's bottom then window will scroll only to some position above the element.
  let targetY = document.body.scrollHeight - elementY < window.innerHeight ? document.body.scrollHeight - window.innerHeight : elementY
	let diff = targetY - startingY
  // Easing function: easeInOutCubic
  // From: https://gist.github.com/gre/1650294
  let easing = function (t: number) { return t<.5 ? 4*t*t*t : (t-1)*(2*t-2)*(2*t-2)+1 }
  let start: any = undefined

  console.log(window, elementY, targetY, diff)

  if (!diff) return

	// Bootstrap our animation - it will get called right before next frame shall be rendered.
	const step = (timestamp: number) => {
    console.log(start, timestamp)
    if (!start) start = timestamp
    // Elapsed miliseconds since start of scrolling.
    var time = timestamp - start
		// Get percent of completion in range [0, 1].
    var percent = Math.min(time / duration, 1)
    // Apply the easing.
    // It can cause bad-looking slow frames in browser performance tool, so be careful.
    percent = easing(percent)

    const root = document.getElementById("root")
    console.log(root)
    if (root != null) {
      root.scrollTo(0, startingY + diff * percent)
    }

		// Proceed with animation as long as we wanted it to.
    if (time < duration) {
      window.requestAnimationFrame(step)
    }
  }
  
  window.requestAnimationFrame(step)
}

export function scrollToElm(container: any, elm: any, duration: number){
  if (container == null || elm == null) {
    return false
  }
  
  let pos = getRelativePos(elm, container)

  scrollTo(container, pos.top - 200, duration / 1000)
}

export function getRelativePos(elm: any, root: any){
  let pPos = root.getBoundingClientRect(), // parent pos
      cPos = elm.getBoundingClientRect(), // target pos
      pos: any = {}

      console.log(cPos.top, pPos.top, root.scrollTop)

  pos.top    = cPos.top    - pPos.top + root.scrollTop
  pos.right  = cPos.right  - pPos.right
  pos.bottom = cPos.bottom - pPos.bottom
  pos.left   = cPos.left   - pPos.left

  return pos
}
    
export function scrollTo(element: any, to: any, duration: number, onDone: any = null) {
  let start = element.scrollTop,
      change = to - start,
      startTime = performance.now(),
      val, now, elapsed, t;

    function animateScroll(){
      now = performance.now()
      elapsed = (now - startTime) / 1000
      t = (elapsed / duration)

      element.scrollTop = start + change * easeInOutQuad(t)

      if( t < 1 )
        window.requestAnimationFrame(animateScroll)
      else
        onDone && onDone()
    }

    animateScroll()
}

export function easeInOutQuad(t: number) { return t<.5 ? 2*t*t : -1+(4-2*t)*t };

export function getElementY(query: string) {
  return window.pageYOffset + document.querySelector(query)!.getBoundingClientRect().top
}

export function Utf8ArrayToStr(array: any) {
  var out, i, len, c;
  var char2, char3;

  out = "";
  len = array.length;
  i = 0;
  while(i < len) {
  c = array[i++];
  switch(c >> 4)
  { 
    case 0: case 1: case 2: case 3: case 4: case 5: case 6: case 7:
      // 0xxxxxxx
      out += String.fromCharCode(c);
      break;
    case 12: case 13:
      // 110x xxxx   10xx xxxx
      char2 = array[i++];
      out += String.fromCharCode(((c & 0x1F) << 6) | (char2 & 0x3F));
      break;
    case 14:
      // 1110 xxxx  10xx xxxx  10xx xxxx
      char2 = array[i++];
      char3 = array[i++];
      out += String.fromCharCode(((c & 0x0F) << 12) |
                     ((char2 & 0x3F) << 6) |
                     ((char3 & 0x3F) << 0));
      break;
  }
  }

  return out;
}


export const fixNumber = (num: number) => {
  if (num < 10) {
    return `0${num}`;
  } else {
    return `${num}`;
  }
}

export const buildParams = (map: any) => {
  let query = "";
  for (let key in map) {
    if (query == "") {
      query = `?${key}=${map[key]}`;
    } else {
      query += `&${key}=${map[key]}`;
    }
  }
  return query;
}

export function urlify(text: string) {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return '<a href="' + url + '" target="_blank">' + url + '</a>';
  })
}

export function isNullOrUndefined<T>(obj: T | null | undefined): obj is null | undefined {
  return typeof obj === "undefined" || obj === null;
}

export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

export function declOfNum(n: number, text_forms: string[]) {  
  n = Math.abs(n) % 100; 
  var n1 = n % 10;
  if (n > 10 && n < 20) { return text_forms[2]; }
  if (n1 > 1 && n1 < 5) { return text_forms[1]; }
  if (n1 == 1) { return text_forms[0]; }
  return text_forms[2];
}

export function translit(word: string) {
	let answer: string = '';
	const converter: any = {
		'а': 'a',    'б': 'b',    'в': 'v',    'г': 'g',    'д': 'd',
		'е': 'e',    'ё': 'e',    'ж': 'zh',   'з': 'z',    'и': 'i',
		'й': 'y',    'к': 'k',    'л': 'l',    'м': 'm',    'н': 'n',
		'о': 'o',    'п': 'p',    'р': 'r',    'с': 's',    'т': 't',
		'у': 'u',    'ф': 'f',    'х': 'h',    'ц': 'c',    'ч': 'ch',
		'ш': 'sh',   'щ': 'sch',  'ь': '',     'ы': 'y',    'ъ': '',
		'э': 'e',    'ю': 'yu',   'я': 'ya',
 
		'А': 'A',    'Б': 'B',    'В': 'V',    'Г': 'G',    'Д': 'D',
		'Е': 'E',    'Ё': 'E',    'Ж': 'Zh',   'З': 'Z',    'И': 'I',
		'Й': 'Y',    'К': 'K',    'Л': 'L',    'М': 'M',    'Н': 'N',
		'О': 'O',    'П': 'P',    'Р': 'R',    'С': 'S',    'Т': 'T',
		'У': 'U',    'Ф': 'F',    'Х': 'H',    'Ц': 'C',    'Ч': 'Ch',
		'Ш': 'Sh',   'Щ': 'Sch',  'Ь': '',     'Ы': 'Y',    'Ъ': '',
		'Э': 'E',    'Ю': 'Yu',   'Я': 'Ya',   ' ': '-',    '&': '-', '?': '',
    '«': '',     '»': '', '': '', ',': '', '!': '', '.': '' , ':': '', ';': '', '@': '', '#': '', '"': '', "'": ''
	};
 
	for (let i = 0; i < word.length; ++i ) {
		if (converter[word[i]] == undefined) {
			answer += word[i]
		} else {
			answer += converter[word[i]]
		}
	}
 
	return answer
}

export function sortById(a: any, b: any) {
  if (a.id > b.id) {
    return 1
  } else if (a.id < b.id) {
    return -1
  } else {
    return 0
  }
}

export function sortByIndex(a: any, b: any) {
  if (a.index > b.index) {
    return 1
  } else if (a.index < b.index) {
    return -1
  } else {
    return 0
  }
}

export function viewportToPixels(value: string) {
  let parts: any = value.match(/([0-9\.]+)(vh|vw)/)
  let q = Number(parts[1])
  let side: number = window['innerHeight']
  return side * (q/100)
}

export function stripPhone(phone: string): string {
  return phone.replaceAll(" ", "").replaceAll("-", "").replaceAll("(", "").replaceAll(")", "")
}

export function isElementInViewport(el?: any | null): boolean {
  if (el == undefined || el == null) {
    return false;
  }
  
  var top = el.offsetTop;
  var left = el.offsetLeft;
  var width = el.offsetWidth;
  var height = el.offsetHeight;

  while(el!.offsetParent) {
    el = el!.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  const cont = document.getElementById("root")

  if (cont != null) {
    // console.log(top, (cont.scrollTop + cont.clientHeight))
  }
  
  return cont != null ? (
    (top + 20) < (cont.scrollTop + cont.clientHeight) &&
    left < (cont.scrollLeft + cont.scrollWidth)
  ) : false
}
