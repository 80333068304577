import { observer } from "mobx-react-lite"
import { FC, useContext, useEffect, useRef, useState } from "react"
import { Context } from ".."

import useWindowDimensions from "../core/hooks/useWindowDimensions"
import { useFetching } from "../core/hooks/useFetching"

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import {isElementInViewport} from "../core/utils/utils"

import styles from "../styles/Home.module.scss"
import Breadcrumbs from "./_shared/Breadcrumbs/Breadcrumbs"
import CardWrap from "./_shared/CardWrap/CardWrap"
import {ApiService} from "../core/api/api"
import {useLocation, useNavigate} from "react-router-dom"
import {XProduct} from "../core/models/store/product"
import RubbleIcon from "../components/icons/RubbleIcon"
import {Carousel} from "antd"
import {FormattedMessage} from "react-intl"

interface IArticle {
  id?: number
  image: string | null
  frame?: string
  title: string
  subtitle: string
  text: string
  source: string | null
  link: string | null
}

const ArticlesData: IArticle[] = []

const ShopsData: any[] = []

const Home: FC = () => {

  const { store, shopStore, studioStore } = useContext(Context)
  const { width } = useWindowDimensions()

  const location = useLocation()

  const navigate = useNavigate()
  
  const swiperEl1 = useRef(null as any)
  const swiperEl2 = useRef(null as any)
  const swiperEl3 = useRef(null as any)

  const [swiper1, setSwiper1] = useState({} as any)
  const [swiper2, setSwiper2] = useState({} as any)
  const [swiper3, setSwiper3] = useState({} as any)

  const [userName, setUserName] = useState("")
  const [userCity, setUserCity] = useState("")
  const [userPhone, setUserPhone] = useState("")

  const [userToken, setUserToken] = useState("")

  const [selImg, setSelImg] = useState("0")
  
  const [clusterPoints, setClusterPoints] = useState([
    [55.686852, 37.852365], [47.232634, 39.625379], [45.119326, 38.927496], [45.097692, 38.973570]
  ] as number[][])

  const [articles, setArticles] = useState([] as IArticle[])

  const [fetchData, isDataLoading, errorData] = useFetching(async () => {
    let lst: IArticle[] = []

    lst = ArticlesData

    setArticles(prev => prev = lst)
  })
  
  const [fetchStudios] = useFetching(async () => {
    const res = await ApiService.studios.getStudios({
      country_id: studioStore.selCountry == null ? 1 : studioStore.selCountry.id,
      city_id: studioStore.selectedCity == null ? 0 : studioStore.selectedCity.id,
      page: studioStore.pageIndex,
      limit: studioStore.pageLimit,
      search: studioStore.search,
      category: studioStore.category,
      order: studioStore.order,
      order_by: studioStore.orderBy,
      status: studioStore.status,
      date: studioStore.searchDate,
      time: studioStore.searchTime,
      service: studioStore.searchService
    })

    studioStore.setTotalStudios(res.data.total)
    studioStore.setStudios(res.data.studios)
  })

  const runAnimations = () => {
    
  }

  const openForm = (title: string) => {
    store.setFormTitle(title)
    store.setIsFormVisible(true)
  }

  const runOpacity = (el: HTMLElement | null, delay: number = 0, asc: number = 0) => {
    if (el != null) {
      // if (width <= 960) {
      //   asc = -40;
      // }

      if (el.style.opacity == "") {
        el.style.opacity = `0`;
      }

      if (isElementInViewport(el)) {
        let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay + asc);
        let spl = dell / 100;  
        
        if (el.style.opacity == "") {
          el.style.opacity = `${spl}`;
        } else {
          
          el.style.opacity = `${spl}`;
        }
      } else {
        if (parseInt(el.style.opacity) == 0) {
          el.style.opacity = `0`;
        }
      }
    }
  }

  const runBottomOpacity = (el: HTMLElement | null, delay: number = 0, asc: number = 0) => {
    if (el != null) {
      // if (width <= 960) {
      //   delay = 40;
      // }

      if (el.style.transform == "") {
        el.style.transform = "translateY(35px)";
      }

      if (el.style.opacity == "") {
        el.style.opacity = "0";
      }

      if (isElementInViewport(el)) {
        let dell = window.innerHeight - ((el.getBoundingClientRect().y + el.getBoundingClientRect().height) - delay + asc);
        let spl = (dell) / 100;
        if (spl > parseInt(el.style.opacity)) {
        }
        el.style.opacity = `${spl}`;
        
        let ix = ((dell) / 4) - 35;

        if (ix > parseInt(el.style.bottom.replace("px", ""))) {
          
        }
        if (ix < 0) {
          el.style.transform = `translateY(${-ix}px)`;
        } else {
          el.style.transform = `translateY(0px)`;
        }
      } else {
        if (el.style.opacity == "0") {
          el.style.opacity = `0`;
          el.style.transform = "translateY(35px)";
        }
      }
    }
  }
  
  const getFinalPrice = (product: XProduct) => {
    if (product.compound == "item") {
      return product.price
    } else {
      return product.price / 10
    }
  }
  
  useEffect(() => {

    fetchData()
    // fetchStudios()

    setTimeout(() => {
      runAnimations()
    }, 400)

    const onScroll = (event: any) => {
      const scrollTop = event.target.scrollTop


    }

    console.log(location.hash)
    if (location.hash != "") {
      setTimeout(() => {
        const hsh = document.querySelector(location.hash)
        if (hsh != null) {
          hsh.scrollIntoView({
            behavior: 'smooth',
            // block: 'start'
          })
        }
      }, 300)
    }

    document.getElementById("root")!.addEventListener('scroll', onScroll)

    return () => {
      document.getElementById("root")!.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <>
      <div className="main_wrapper">
        
        <div className={styles.head}>
          <div className="container">
            <div className="row">
              {width < 768 ? <div className="col-12 col-md-6">
                <div className={styles.xcarousel}>
                  <Carousel arrows dots={false} autoplay autoplaySpeed={3000}>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/1.png)`}}></div>
                    </div>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/2.jpg)`}}></div>
                    </div>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/3.jpg)`}}></div>
                    </div>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/4.jpg)`}}></div>
                    </div>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/5.jpg)`}}></div>
                    </div>
                  </Carousel>
                </div>
              </div> : <></>}
              <div className="col-12 col-md-6">
                <strong><FormattedMessage id="home_title" /></strong>
                <h1><FormattedMessage id="home_subtitle" /></h1>
                <p><FormattedMessage id="home_text" /></p>

                <div className={styles.action}>
                  <a href="/studios"><FormattedMessage id="home_action" /></a>
                </div>
              </div>
              {width > 768 ? <div className="col-12 col-md-6">
                <div className={styles.xcarousel}>
                  <Carousel arrows dots={false} autoplay autoplaySpeed={3000}>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/1.png)`}}></div>
                    </div>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/2.jpg)`}}></div>
                    </div>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/3.jpg)`}}></div>
                    </div>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/4.jpg)`}}></div>
                    </div>
                    <div>
                      <div className={styles.ximage} style={{backgroundImage: `url(/assets/images/home/head/5.jpg)`}}></div>
                    </div>
                  </Carousel>
                </div>
              </div> : <></>}
            </div>
          </div>
        </div>

        <div className={styles.features}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2><FormattedMessage id="home_why" /></h2>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className={styles.fcard}>
                      <h3><FormattedMessage id="home_why_b1" /></h3>
                      <p><FormattedMessage id="home_why_b1_txt" /></p>
                    </div>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className={styles.fcard}>
                      <h3><FormattedMessage id="home_why_b2" /></h3>
                      <p><FormattedMessage id="home_why_b2_txt" /></p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-4">
                    <div className={styles.fcard + " " + styles.fixed}>
                      <h3><FormattedMessage id="home_why_b3" /></h3>
                      <p><FormattedMessage id="home_why_b3_txt" /></p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className={styles.fcard + " " + styles.fixed}>
                      <h3><FormattedMessage id="home_why_b4" /></h3>
                      <p><FormattedMessage id="home_why_b4_txt" /></p>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className={styles.fcard + " " + styles.fixed}>
                      <h3><FormattedMessage id="home_why_b5" /></h3>
                      <p><FormattedMessage id="home_why_b5_txt" /></p>
                    </div>
                  </div>
                </div>

                <p className={styles.cntrx}><FormattedMessage id="home_why_txt" /></p>

                <div className={styles.action + " " + styles.full}>
                  <a href="/studios"><FormattedMessage id="home_action" /></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.categories}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2><FormattedMessage id="home_cats" /></h2>

                <div className="row">
                  <div className="col-12 col-md-6">
                    <div className={styles.cats}>
                      {studioStore.allCats.slice(0, 8).map((cat, ix) => <div className={styles.cat_item} onClick={() => {
                        studioStore.setSelectedCat(cat)
                        setTimeout(() => {
                          navigate("/studios")
                        }, 500)
                      }} onMouseEnter={() => studioStore.setSelCat(ix)} onMouseLeave={() => studioStore.setSelCat(0)}><span>{cat.name}</span><b><i className="fal fa-circle-arrow-right"></i></b></div>)}
                    </div>

                    <a href="/studios"><FormattedMessage id="home_cats_all" /> <b><i className="far fa-arrow-right-long"></i></b></a>
                  </div>
                  <div className="col-12 col-md-6">
                    <div className={styles.cat_preview}>
                      {studioStore.allCats.map((cat, ix) => <div style={{backgroundImage: `url(${cat.image})`, opacity: ix == studioStore.selCat ? "1" : "0"}}></div>)}
                    </div>
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>

        <div className={styles.partners}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2><FormattedMessage id="home_partners" /></h2>

                {width > 768 ? <>
                  <div className={styles.plist}>
                    <a href="#"><img src="/assets/images/home/partners/yandex-music.png" /></a>
                    <a href="#"><img src="/assets/images/home/partners/vk_music.png" /></a>
                    <a href="#"><img src="/assets/images/home/partners/zvuk_sber.png" /></a>
                    <a href="#"><img src="/assets/images/home/partners/dzen.png" /></a>
                  </div>
                  {/* <div className={styles.plist + " " + styles.last}>
                    <a href="#"><img src="https://optim.tildacdn.com/tild3166-6531-4536-b865-353065336335/-/resize/474x/-/format/webp/Mellstroy_1.png" /></a>
                    <a href="#"><img src="https://zipm.ru/pictures/logo.png" /></a>
                    <a href="#"><img src="https://www.astro-seek.com/seek-images/bannery_2015/seek_logo_200_eye_ru.gif" /></a>
                  </div> */}
                </> : <>
                  <div className={styles.plist}>
                    <a href="#"><img src="/assets/images/home/partners/yandex-music.png" /></a>
                    <a href="#"><img src="/assets/images/home/partners/vk_music.png" /></a>
                  </div>
                  <div className={styles.plist}>
                    <a href="#"><img src="/assets/images/home/partners/zvuk_sber.png" /></a>
                    <a href="#"><img src="/assets/images/home/partners/dzen.png" /></a>
                  </div>
                </>}
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default observer(Home)